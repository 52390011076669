<template>
  <UtilMessage v-if="compErrors.length && formSubmissionAttempted" type="error" class="form-error-report" large>
    <strong>Review errors in this form</strong>

    <ul class="mt-2 ml-5 list-disc">
      <li v-for="(error, index) in compErrors" :key="index">
        {{ upperFirst(error) }}
      </li>
    </ul>
  </UtilMessage>
</template>

<script>
import { upperFirst } from 'lodash'
import UtilMessage from '@/components/utils/UtilMessage'

// Don't bother putting an v-if when using this component, we'll handle
// the display condition here to reduce verbosity
export default {
  name: 'FormErrorReport',
  components: {
    UtilMessage
  },
  props: {
    formErrors: {
      type: Object,
      required: true
    },
    formSubmissionAttempted: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      upperFirst
    }
  },
  computed: {
    compErrors() {
      let errors = []

      // eslint-disable-next-line no-unused-vars
      Object.entries(this.formErrors).forEach(([fieldName, fieldErrors]) => {
        if (fieldErrors.length > 0) {
          errors.push(fieldErrors[0])
        }
      })

      return errors
    }
  }
}
</script>
