<template>
  <UtilMessage v-if="compErrors" type="error" large>
    <span v-if="!showOnlyMessage">There was a problem with that request.</span>

    <div class="api-errors mt-2" v-html="compErrors" />
  </UtilMessage>
</template>

<script>
import UtilMessage from '@/components/utils/UtilMessage'

// Don't bother putting an v-if when using this component, we'll handle
// the display condition here to reduce verbosity
export default {
  name: 'UtilApiError',
  components: {
    UtilMessage
  },
  props: {
    models: {
      type: Array,
      required: true
    },
    showOnlyMessage: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    async compErrors(newValue) {
      if (newValue) {
        await this.$nextTick()
        this.$scroll.intoViewIfNeeded(document.querySelector('.api-errors'))
      }
    }
  },
  computed: {
    compErrors() {
      let errorMessages = []

      this.models.forEach(api => {
        if (api.status === 'error' && api.message) {
          errorMessages.push(api.message)
        }
      })

      // Remove duplicate error messages from array
      errorMessages = [...new Set(errorMessages)]

      return errorMessages.join('<br />')
    }
  }
}
</script>

<style lang="scss">
.api-errors ul {
  display: inline-block;
  margin-top: 0px;
  margin-left: 0px;
}
</style>
