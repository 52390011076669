export default function (existingLead = {}) {
  return {
    platform: 'sellmylivestock',
    createdBy: null,
    title: '',
    detail: '',
    priority: 'MEDIUM',
    assignee: '',
    customers: [{}],
    counterparties: [{}],
    listing: { ...existingLead.listing },
    ...existingLead
  }
}
