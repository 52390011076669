<template>
  <ModalBase :name="$options.name" title="Add New Lead" modalContainerClass="max-w-4xl bg-white">
    <template #body="{ closeModal }">
      <FormLeadNew ref="formLeadNew" @close-modal="closeModal" />
    </template>
  </ModalBase>
</template>

<script>
import ModalBase from '@/components/modals/ModalBase'
import FormLeadNew from '@/components/forms/FormLeadNew.vue'

export default {
  name: 'ModalLeadNew',
  components: {
    ModalBase,
    FormLeadNew
  },
  props: {
    modalData: {
      type: Object,
      required: true
    }
  }
}
</script>
