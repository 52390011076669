<template>
  <Form @submit="createLead()" ref="form" v-slot="{ formErrors, formSubmissionAttempted }">
    <FormRadio
      name="platform"
      v-model="createLeadApi.model.platform"
      :items="[
        {
          text: 'SellMyLivestock',
          value: 'sellmylivestock'
        },
        {
          text: 'Graindex',
          value: 'graindex'
        }
      ]"
      horizontal
    />

    <FormLabel>Customer</FormLabel>

    <template v-if="!createLeadApi.model.customers[0].name">
      <FormTextInput
        name="memberSearch"
        type="search"
        placeholder="Search members by email, name, userRef, number"
        v-model="memberSearchApi.model.search"
        :iconPrefix="faSearch"
        @input="searchMembers($event)"
        @keydown.native.enter.exact.prevent="searchMembers($event)"
        autocomplete="off"
        class=""
        targetClass="w-full"
      />

      <UtilLoader :isLoading="memberSearchApi.isLoading">
        <h4
          v-if="memberSearchApi.data.customers && memberSearchApi.data.customers.length > 0"
          class="mb-1 font-medium text-base"
        >
          Select Customer
        </h4>

        <div
          v-for="customer in memberSearchApi.data.customers"
          :key="customer._id"
          @click="selectMember(customer)"
          class="flex items-center justify-between w-full hover:bg-yellow-100 even:bg-gray-100 px-2 py-1 cursor-pointer"
        >
          <span>{{ customer.name }}</span>
          <span>{{ customer.contact }}</span>
          <span>{{ customer.email || customer.profile.emailAddress }}</span>
        </div>
      </UtilLoader>

      <FormTextInput type="hidden" v-model="compCustomer.name" rules="required" name="customer" />
    </template>

    <div v-else class="">
      <div class="flex items-center">
        <p>{{ compCustomer.name }}</p>

        <UtilTextLink @click="resetMember()" class="ml-2">change</UtilTextLink>
      </div>

      <template v-if="compHasCustomer && compCustomer.leads && compCustomer.leads.length > 0">
        <UtilDivider class="my-4" />

        <h4 class="font-medium text-lg mt-4">Existing Leads</h4>

        <p>Check these member's existing open leads to avoid duplicates</p>

        <div class="flex flex-wrap">
          <UtilLead
            v-for="lead in compCustomer.leads"
            :key="lead.leadId"
            :lead="lead"
            @click="openLead(lead)"
            class="cursor-zoom border border-gray-400 mt-2 mr-2 w-3/12"
          />
        </div>

        <UtilDivider class="my-4" />
      </template>
    </div>

    <!-- <div class="flex justify-between mt-4">
      <div class="border border-gray rounded px-2 py-2 w-6/12">
        <h4 class="font-medium text-lg">
          <FontAwesomeIcon :icon="faPeopleArrows" class="mr-1" />
          Listing
        </h4>

        <div class="flex items-center">
          <MultiSelect
            v-model="createLeadApi.model.listingRef"
            :options="compCustomerListings"
            track-by="value"
            label="label"
            placeholder="Select listing if applicable"
            :showLabels="false"
            :limit="1"
            class="mt-2"
          >
            <template slot="option" slot-scope="props">
              {{ props.option.label }}
            </template>
          </MultiSelect>

          <UtilButton color="secondary" class="mt-2 ml-4">
            <FontAwesomeIcon :icon="faPlusCircle" />
            <span>New Listing</span>
          </UtilButton>
        </div>
      </div>

      <div class="border border-gray border-gray rounded px-2 py-2 flex-grow ml-4">
        <h4 class="font-medium text-lg">
          <FontAwesomeIcon :icon="faTruck" class="mr-1" />
          Haulage
        </h4>

        <UtilButton color="secondary" class="mt-2">
          <FontAwesomeIcon :icon="faPlusCircle" />
          <span>New Quote</span>
        </UtilButton>
      </div>
    </div> -->

    <div v-if="compHasCustomer" class="mt-4">
      <FormLabel>Listing</FormLabel>

      <MultiSelect
        v-if="compCustomerListings.length > 0"
        v-model="chosenListingRef"
        :options="compCustomerListings"
        @select="onListingChosen"
        track-by="value"
        label="label"
        placeholder="Select listing if applicable"
        :showLabels="false"
        :multiple="false"
        :limit="1"
      >
        <template slot="option" slot-scope="props">
          {{ props.option.label }}
        </template>
      </MultiSelect>

      <p v-else class="text-gray-darker italic">Customer does not have any existing listings yet</p>
    </div>

    <FormTextInput
      name="description"
      placeholder="e.g Matchmaker for 100 Store Bulls"
      label="Description"
      v-model="createLeadApi.model.title"
      rules="required"
      class="mt-2"
      targetClass="w-full"
    />

    <FormTextInput
      textArea
      :rows="2"
      ref="detail"
      name="detail"
      label="Additional Detail"
      v-model="createLeadApi.model.detail"
      placeholder="Add any initial notes"
      autoGrow
      class="flex-grow"
      targetClass="w-full"
    />

    <div class="border border-gray rounded mt-4 px-2 py-1">
      <h4 class="font-medium text-lg">
        Other Party
        <span class="text-gray text-sm ml-1 inline-block">optional if unknown</span>
      </h4>

      <div class="flex items-center justify-between">
        <FormTextInput
          name="name"
          label="Name"
          v-model="createLeadApi.model.counterparties[0].name"
          autocapitalize="none"
          autocorrect="off"
          spellcheck="false"
          class="flex-grow mr-4"
        />

        <FormTextInput
          name="contactNumber"
          label="Contact Number"
          type="tel"
          :mask="telephoneMask"
          v-model="createLeadApi.model.counterparties[0].contact"
          autocapitalize="none"
          autocorrect="off"
          spellcheck="false"
          class="flex-grow mr-4"
        />

        <FormTextInput
          name="email"
          label="Email"
          v-model="createLeadApi.model.counterparties[0].email"
          rules="email"
          autocapitalize="none"
          autocorrect="off"
          spellcheck="false"
          class="flex-grow"
        />
      </div>
    </div>

    <div class="flex align-center mt-2">
      <FormSelect
        name="priority"
        label="Priority"
        v-model="createLeadApi.model.priority"
        :items="[
          {
            label: 'Low',
            value: 'LOW'
          },
          {
            label: 'Medium',
            value: 'MEDIUM'
          },
          {
            label: 'High',
            value: 'HIGH'
          }
        ]"
        :emptyFirstOption="false"
        class="mr-4 mb-0"
      />

      <div class="flex-grow max-w-xs mr-4">
        <FormLabel>Assigned To</FormLabel>

        <UtilAssignee v-model="createLeadApi.model.assignee" />
      </div>
    </div>

    <FormErrorReport class="mt-4" :formSubmissionAttempted="formSubmissionAttempted" :formErrors="formErrors" />

    <UtilApiError :models="[createLeadApi]" class="mt-4" />

    <UtilDivider class="mt-6" />

    <div class="flex items-center mt-6">
      <UtilButton color="primary" type="submit" :isLoading="createLeadApi.isLoading" class="mr-6">
        Create Lead
      </UtilButton>

      <UtilButton @click="$emit('close-modal')" textLink>Cancel</UtilButton>
    </div>
  </Form>
</template>

<script>
import { faSearch, faPlusCircle } from '@fortawesome/pro-regular-svg-icons'
import { faTruck, faPeopleArrows } from '@fortawesome/pro-solid-svg-icons'
import Api from '@/lib/api'
import { debounce, get } from 'lodash'
import telephoneMask from '@/lib/masks/telephone'
import LeadModel from '@/lib/models/lead'
import UtilButton from '@/components/utils/UtilButton'
import UtilTextLink from '@/components/utils/UtilTextLink'
import UtilApiError from '@/components/utils/UtilApiError'
import Form from '@/components/forms/elements/Form'
import FormErrorReport from '@/components/forms/elements/FormErrorReport'
import FormRadio from '@/components/forms/elements/FormRadio'
import FormTextInput from '@/components/forms/elements/FormTextInput'
import UtilLoader from '@/components/utils/UtilLoader'
import UtilDivider from '@/components/utils/UtilDivider'
import FormLabel from '@/components/forms/elements/FormLabel.vue'
import FormSelect from '@/components/forms/elements/FormSelect.vue'
import UtilLead from '@/components/utils/UtilLead.vue'
import UtilAssignee from '@/components/utils/UtilAssignee.vue'

export default {
  name: 'FormLeadNew',
  components: {
    UtilButton,
    UtilTextLink,
    UtilApiError,
    Form,
    FormErrorReport,
    FormRadio,
    FormTextInput,
    UtilLoader,
    UtilDivider,
    FormLabel,
    FormSelect,
    UtilLead,
    UtilAssignee
  },
  data() {
    return {
      createLeadApi: new Api(new LeadModel()),
      memberSearchApi: new Api({ search: '', per_page: 10 }),
      chosenListingRef: null,
      faSearch,
      faPlusCircle,
      faTruck,
      faPeopleArrows,
      telephoneMask
    }
  },
  created() {
    this.createLeadApi.model.platform =
      window.localStorage.getItem('sml-crm-lead-filters-platform') || 'sellmylivestock'

    this.createLeadApi.model.assignee = this.app.user.id
    this.createLeadApi.model.createdBy = this.app.user.id
  },
  computed: {
    compHasCustomer() {
      return get(this.createLeadApi, 'model.customers[0].name') ? true : false
    },

    compCustomer() {
      return get(this.createLeadApi, 'model.customers[0]', {})
    },

    compCustomerListings() {
      if (!this.compHasCustomer || !get(this.compCustomer, 'listings')) {
        return []
      }

      return this.compCustomer.listings.map(listing => ({
        label: this.$listingTitle(listing),
        value: listing.listingRef
      }))
    }
  },
  methods: {
    async createLead() {
      await this.createLeadApi.post('/v2/crm/leads')

      this.$notify.floatSuccess(`${this.createLeadApi.data.leadID} successfully created`)

      this.$emit('close-modal')
    },

    searchMembers: debounce(async function (searchQuery) {
      console.log('Search members', searchQuery)

      if (searchQuery === '' || searchQuery.length < 3) {
        this.memberSearchApi.data = { ...this.memberSearchApi.data, customers: [] }
      } else {
        await this.memberSearchApi.get('/v2/crm/customers?listings=true&leads=true&lookup=true')
      }
    }, 500),

    selectMember(customer) {
      this.$set(this.createLeadApi.model, 'customers', [{ ...customer }])
    },

    resetMember() {
      this.$set(this.createLeadApi.model, 'customers', [{}])
      this.chosenListingRef = null
    },

    onListingChosen(selected) {
      let matchingListing = this.compCustomer.listings.find(listing => listing.listingRef === selected.value)

      this.createLeadApi.model.listing.listingData = matchingListing
      this.createLeadApi.model.title = this.$listingTitle(matchingListing)
    },

    openLead(lead) {
      console.log({ lead })

      // We don't have all the lead data but enough to show something while the rest loads
      this.$modal.open('ModalLead', lead)
    }
  }
}
</script>
