<template>
  <FormInputValidation :name="name" :rules="rules" v-slot="{ errors }">
    <FormLabel v-if="label || $slots.label">
      {{ label }}
      <slot name="label"></slot>
    </FormLabel>

    <div :class="{ 'flex align-middle': horizontal, 'flex flex-col items-start': !horizontal }">
      <label
        v-for="item in items"
        :key="item.value"
        :data-tooltip="item.tooltip"
        class="form-radio cursor-pointer"
        :class="[
          { disabled: item.disabled, tooltip: item.tooltip, 'mt-2': !horizontal, 'mr-3': item.text !== '' }
        ]"
      >
        <input type="radio" :name="name" :disabled="item.disabled" :value="item.value" v-model="compValue" />

        <FontAwesomeIcon :icon="faCircle" class="icon icon-unselected" />
        <FontAwesomeIcon :icon="faDotCircle" class="icon icon-selected" />

        <span class="label select-none">
          {{ item.text }}
        </span>
      </label>
    </div>

    <FormInputHint :error="showValidationError ? errors[0] : undefined" :hint="hint">
      <template slot="hint">
        <slot name="hint"></slot>
      </template>
    </FormInputHint>
  </FormInputValidation>
</template>

<script>
import FormInputValidation from '@/components/forms/elements/FormInputValidation'
import FormInputHint from '@/components/forms/elements/FormInputHint'
import FormLabel from '@/components/forms/elements/FormLabel'
import { faCircle } from '@fortawesome/pro-light-svg-icons'
import { faDotCircle } from '@fortawesome/pro-regular-svg-icons'

export default {
  name: 'FormRadio',
  components: {
    FormInputValidation,
    FormInputHint,
    FormLabel
  },
  props: {
    label: String,
    rules: [String, Object],
    hint: String,
    value: [Boolean, String, Number],
    name: {
      type: String,
      required: true
    },
    items: {
      type: Array,
      default() {
        return []
      }
    },
    horizontal: {
      type: Boolean,
      default: false
    },
    showValidationError: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      faCircle,
      faDotCircle
    }
  },
  computed: {
    compValue: {
      set(newVal) {
        this.$emit('input', newVal)
      },
      get() {
        return this.value
      }
    }
  }
}
</script>
